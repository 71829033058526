exports.components = {
  "component---src-routes-404-js": () => import("./../../../src/routes/404.js" /* webpackChunkName: "component---src-routes-404-js" */),
  "component---src-routes-about-js": () => import("./../../../src/routes/about.js" /* webpackChunkName: "component---src-routes-about-js" */),
  "component---src-routes-business-js": () => import("./../../../src/routes/business.js" /* webpackChunkName: "component---src-routes-business-js" */),
  "component---src-routes-index-js": () => import("./../../../src/routes/index.js" /* webpackChunkName: "component---src-routes-index-js" */),
  "component---src-routes-news-js": () => import("./../../../src/routes/news.js" /* webpackChunkName: "component---src-routes-news-js" */),
  "component---src-routes-products-js": () => import("./../../../src/routes/products.js" /* webpackChunkName: "component---src-routes-products-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

