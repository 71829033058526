module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"衣庫國際服裝形象官網","short_name":"衣庫國際服裝","description":"【買衣服 | 找衣庫】 衣庫國際丨全台最大空白成衣供應商","lang":"zh-hant","display":"standalone","icon":"src/assets/favicon.png","start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0cdbc8b8ea2ff03e916c4465ba1530cb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
